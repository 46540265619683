import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Breadcrumb from "../components/SectionHeader";
import Footer from "../components/shared/Footer/Footer";
import HomeTwoNavbar from "./Home/HomeTwoNavbar/HomeTwoNavbar";

const Reviews = () => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <HomeTwoNavbar />
      <Breadcrumb bgimage={rpdata?.gallery?.[7]} title="Reviews" />

      <div className="mt-100 mb-100">
        <h1 className="text-center">What Our Clients Say</h1>
        <div className="container">
        <div class="elfsight-app-41e1e6fe-1763-4452-bf6a-5b99448f1b06"></div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Reviews;
