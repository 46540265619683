import React, { useContext } from "react";
import { GlobalDataContext } from "../../../context/context";
import Footer from "../../../components/shared/Footer/Footer";
import HomeTwoNavbar from "../../Home/HomeTwoNavbar/HomeTwoNavbar";
import HomeArea from "../HomeArea/HomeArea";
import Directories from "../Home/BannerArea/BannerArea";
import AboutArea from "./AboutArea/AboutArea";
import ChoseArea from "./ChoseArea/ChoseArea";
import HeroSection from "./HeroSection/HeroSection";
import ProgressArea from "./ProgressArea/ProgressArea";
import ServiceHome from "../Home/ServicesHome/ServiceHome";
import WorkingProcess from "../../../pages/About/About/WorkingProcess/WorkingProcess";
import ContactMap from "../../Contact/Contact/ContactMap/ContactMap";
import Modal from "../Home/Modal/Modal";
import VideoPromocional from "./VideoPromocional";

const Home = () => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <HomeTwoNavbar />
      <HeroSection />
      <HomeArea />
      <Directories />
      <VideoPromocional
          linkVideo={`${rpdata?.videosPromo?.[0].link}`}
          image={`${rpdata?.videosPromo?.[0].img}`}
        />
      <ProgressArea />
      <AboutArea />
      <WorkingProcess />
      <div className="pt-100">
         <h1 className="text-center">What Our Clients Say</h1>
        <script src="https://apps.elfsight.com/p/platform.js" defer></script>
        <div class="elfsight-app-41e1e6fe-1763-4452-bf6a-5b99448f1b06"></div>
      </div>
      <ServiceHome />
      <ChoseArea />
      <ContactMap />
      <Modal />
      <Footer />
    </>
  );
};

export default Home;
