import React, { useContext } from "react";
import { GlobalDataContext } from "../../../../context/context";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";

const GalleryPage = (props) => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div className="container pd-top-150 pd-bottom-100">
      <h2 className="text-center pt-100">Our Recent Projects</h2>
      {rpdata?.landings?.map((item, index) => {
        return (
          <div>
            <h2 className="text-center">{item.name}</h2>
            <div>
              {item.gallery.map((item, index) => {
               return(
                <img src={item} alt="Img" className="img_projects"/>
               )
              })}
            </div>
          </div>
        );
      })}
      <div className="gallery-content">
        <Gallery>
          {rpdata?.gallery?.map((item, index) => (
            <Item
              original={item}
              thumbnail={item}
              width="1024"
              height="768"
              padding="10px"
            >
              {({ ref, open }) => <img ref={ref} onClick={open} src={item} alt=""/>}
            </Item>
          ))}
        </Gallery>
      </div>
    </div>
  );
};

export default GalleryPage;
