import React, { useContext } from 'react'
import { GlobalDataContext } from "../../../context/context";

const VideoPromocional = ({ linkVideo, image }) => {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div
            className='bgCountentVideo'
            style={{
                backgroundImage: `url("${image ? image : rpdata?.gallery?.[0]}")`
            }}
        >
            <div className='countentVideoInfo '>
                <div className='info'>
                    {/* <h3>{title}</h3> */}
                    {/* <p>{text}</p> */}
                </div>
                        <iframe
                            title="Promotional video"
                            // style={{backgroundImage: `url("${image ? image : rpdata?.gallery?.[0]}")`}}
                            className='w-[90%] h-[280px] md:h-[360px] md:w-[50%] lg:h-[460px] lg:w-[60%] mx-auto rounded-3xl relative top-[80px] md:top-[150px] promotional_video'
                            src={`${linkVideo}`}
                            width="640" height="360"
                            frameborder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen
                        />
            </div>
        </div>
    )
}

export default VideoPromocional


